
function Footer() {
    return (
        <div className='footer-area '>
            <div className='row'>
                <span className='footer-font'>
                    <h5>Version 1.6</h5>
                </span>
            </div>
        </div>
    )
}

export default Footer